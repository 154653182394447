import React, { useState } from "react"
// import { Link } from "gatsby"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FormSuccessMessage from "../../components/form-success-message/form-success-message"

import "../../styles/services.scss"

const ElectionalAstrology = () => {
  const [submissionStatus, setSubmissionStatus] = useState("")
  const handleSubmissionStatus = axiosStatus => {
    setSubmissionStatus(axiosStatus)
  }

  const [occassion, setOccassion] = useState("")

  return (
    <Layout>
      <SEO
        title="Electional astrology"
        description="Get the auspicious time for your auspicious occasions."
        keyword="Electional astrology, muhoortham, muhoortha, auspicious time, wedding, segam, inauguration, vidhyarambham, astrology, online, suffering, life"
      />
      <div className="main-sub-wrapper">
        <div className="header-wrap">
          <h1 className="header__title h1_decorative">Electional astrology</h1>
          {/* <div className="tg-list-item">
            <input
              className="tgl tgl-flip"
              id="language-checkbox"
              type="checkbox"
            />
            <label
              className="tgl-btn"
              data-tg-off="മലയാളം"
              data-tg-on="English"
              htmlFor="language-checkbox"
            ></label>
          </div> */}
          <div className="text-left">
            <p>Why we look up muhoortham?</p>
            <p>
              It is the second branch of the astrology. After calculations,
              "Muhoorthams" come up. Even in Vedic times the "yagas" (Soma,
              athirathra, bajpeyam etc), Ekadasi, Amavasi etc. matters let the
              astrology gain the "Vedangathvam".
            </p>
            <p>
              As per the varying "Grahayogas" varying results happen. The future
              of one incident will depend on the planetary state at it's initial
              times. Owing to this, the times having "Shubhagrahasthithi" will
              be be sought after and once utilised, everything will be leading
              to good times ahead. This type of astrology where the auspicious
              times are calculated thinking the "Shuddha shuba" matters is known
              as the electional astrology.
            </p>
          </div>
          {/* <div className="text-left malayalam">
            <p>എന്തിനാണ് മൂഹൂർത്തം നോക്കൂന്നത്?</p>
            <p>
              ജ്യോതിഷത്തിന്റെ രണ്ടാമത്തെ ശാഖയാണ് മൂഹൂർത്തം അഥവാ electional
              astrology. ഗണിതം കഴിഞ്ഞാൽ മൂഹൂർത്തങ്ങൾ വരുന്നു. വൈദിക കാലങ്ങളിൽ
              പോലും സോമയാഗം, അതിരാത്രം, വാജപേയം മുതലായവയക്കും ഏകാദശി, അമവാസി
              മുതലായവ യജ്ഞങ്ങൾക്കുമുള്ള കാലവ്യഞ്ജകത്വം. തുടങ്ങിയവയുടെ
              കാര്യങ്ങളാൽ ആണ് ജ്യോതിഷത്തിന് വേദാംഗത്വം ലഭിച്ചത്.
            </p>
            <p>
              വിവിധ ഗ്രഹയോഗങ്ങളുടെ കാലത്ത് വിവിധങ്ങളായ ഫലങ്ങൾ ഉണ്ടാകുന്നു. ഒരു
              സംഭവത്തിന്റെ ഭാവി അതു തുടങ്ങുന്ന സമയെത്ത ഗ്രഹസ്ഥിതിക്ക്
              അനൂസരിച്ചായിരിക്കും എന്നു വ്യക്തമാകുന്നു. അതിനാല് ശുഭ
              ഗ്രഹസ്ഥിതിയുളള കാലങ്ങളിൽ തുടങ്ങൂന്ന കാര്യങ്ങൾ ശുഭമായി അനൂഭവിക്കും.
              അതിനാൽ വരാൻ പോകുന്ന ഒരു കാര്യം മുൻകൂട്ടി നിശ്ചയിക്കുന്ന
              ശുഭമുഹൂർത്തത്തിൽ തുടങ്ങാൻ കഴിയും. അതിന്റെ ശുദാ ശുഭകാര്യങ്ങളും
              ചിന്തിച്ച് ആ വ്യക്തിക്ക് ചേരുന്ന സമയം പറഞ്ഞു കൊടുക്കുന്ന ശാഖയാണ്
              electional astrology.
            </p>
          </div> */}
        </div>
        <div className="service-inner-wrap">
          <h2 className="h4">Process and Fees</h2>
          <p>
            Once you submit the form and make the payment, our astrologers get
            to the calculations and will give you the result over the contact
            address you mention in the email. If you have questions after
            reading the result, then you can always communicate with our
            astrologers over email, phone, Skype etc.
          </p>
          <p>
            <b>Fees:</b> ₹500 (INR) or $10 (USD)
          </p>
          <Formik
            initialValues={{
              name: "",
              email: "",
              applicantRelationship: "",
              occassion: "",
              personName: "",
              personGender: "",
              bday: "",
              personTob: "",
              personPob: "",
              personStar: "",
              message: "",
            }}
            validate={values => {
              const errors = {}
              if (!values.name) {
                errors.name = "Please enter you name"
              }
              if (!values.email) {
                errors.email =
                  "Please enter you e-mail or phone number so that we could contact you"
              } else if (
                !(
                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    values.email
                  ) || /^(\+\d{1,3}[- ]?)?\d{10}$/i.test(values.email)
                )
              ) {
                errors.email = "Invalid email or number"
              }
              if (!values.applicantRelationship) {
                errors.applicantRelationship =
                  "Please enter you relationship with the person"
              }
              if (!values.personName) {
                errors.personName = "Please enter person's name"
              }
              if (!values.personGender) {
                errors.personGender = "Please enter person's gender"
              }
              if (!values.bday) {
                errors.bday = "Please enter person's date of birth"
              }
              if (!values.personTob) {
                errors.personTob = "Please enter person's time of birth"
              }
              if (!values.personPob) {
                errors.personPob = "Please enter person's place of birth"
              }
              if (!values.personStar) {
                errors.personStar = "Please enter person's star"
              }
              if (!values.occassion) {
                errors.occassion = "Please select the occassion"
              }
              if (values.policy === true) {
                errors.policy = "Please check to proceed"
              }
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false)
              axios
                // .post(
                //   "https://getform.io/f/a2c6299e-045d-4a63-a8d1-22b082f6bbb4",
                //   values
                // )
                .post("https://formspree.io/mpzykplw", values)
                .then(res => {
                  handleSubmissionStatus("success")
                })
                .catch(err => {
                  handleSubmissionStatus("failure")
                  console.log(err)
                })
            }}
          >
            {({ isSubmitting }) => (
              <Form className="form">
                {submissionStatus === "failure" ? (
                  <p className="form__main-error">
                    Sorry, please send again, the message wasn't sent.
                  </p>
                ) : (
                  ""
                )}

                {submissionStatus === "success" ? (
                  <FormSuccessMessage fees="500"></FormSuccessMessage>
                ) : (
                  <>
                    <h6 className="form__section-title">
                      Details of you - the applicant
                    </h6>
                    <div className="form__group">
                      <label htmlFor="name" className="form__label">
                        Name*
                      </label>
                      <Field
                        type="text"
                        name="name"
                        id="name"
                        autocomplete="name"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="name"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="email" className="form__label">
                        Contact Info*
                      </label>
                      <Field
                        type="text"
                        name="email"
                        id="email"
                        autocomplete="email"
                        className="form__input"
                        aria-required="true"
                        placeholder="e-mail or phone number"
                      />
                      <ErrorMessage
                        name="email"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label
                        htmlFor="applicantRelationship"
                        className="form__label"
                      >
                        Relationship with the person*
                      </label>
                      <Field
                        type="text"
                        name="applicantRelationship"
                        id="applicantRelationship"
                        className="form__input"
                        placeholder="I am his/her"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="applicantRelationship"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <fieldset className="form__group">
                      <legend>Occassion/Event*</legend>
                      <label>
                        <Field
                          type="radio"
                          name="occassion"
                          value="houseWarming"
                          onClick={() => setOccassion("houseWarming")}
                        />
                        House warming
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="occassion"
                          value="commercialInauguration"
                          onClick={() => setOccassion("commercialInauguration")}
                        />
                        Commercial inauguration
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="occassion"
                          value="chorunnu"
                          onClick={() => setOccassion("chorunnu")}
                        />
                        Chorunnu
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="occassion"
                          value="vidhyarambham"
                          onClick={() => setOccassion("vidhyarambham")}
                        />
                        Vidhyarambham
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="occassion"
                          value="wedding"
                          onClick={() => setOccassion("wedding")}
                        />
                        Wedding
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="occassion"
                          value="segam"
                          onClick={() => setOccassion("segam")}
                        />
                        Segam
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="occassion"
                          value="other"
                          onClick={() => setOccassion("other")}
                        />
                        Other
                      </label>
                      <ErrorMessage
                        name="occassion"
                        component="p"
                        className="form__unit-error"
                      />
                    </fieldset>
                    <h6 className="form__section-title">
                      Details of the person
                    </h6>
                    <div className="form__group">
                      <label htmlFor="personName" className="form__label">
                        Name*
                      </label>
                      <Field
                        type="text"
                        name="personName"
                        id="personName"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="personName"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="personGender" className="form__label">
                        Gender*
                      </label>
                      <Field
                        as="select"
                        name="personGender"
                        id="personGender"
                        className="form__input"
                        aria-required="true"
                      >
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Field>
                      <ErrorMessage
                        name="personGender"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="bday" className="form__label">
                        Date of birth*
                      </label>
                      <Field
                        type="date"
                        max={
                          new Date(
                            new Date().getTime() -
                            new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                        name="bday"
                        id="bday"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="bday"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="personTob" className="form__label">
                        Time of birth*
                      </label>
                      <Field
                        type="time"
                        name="personTob"
                        id="personTob"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="personTob"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="personPob" className="form__label">
                        Place of birth* (nearest city)
                      </label>
                      <Field
                        type="text"
                        name="personPob"
                        id="personPob"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="personPob"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="personStar" className="form__label">
                        Nakshatra/Birthstar*
                      </label>
                      <Field
                        as="select"
                        name="personStar"
                        id="personStar"
                        className="form__input"
                        aria-required="true"
                      >
                        <option value="">Select</option>
                        <option value="unknown">
                          Unknown (its fine, we will calculate it.)
                        </option>
                        <option value="aswathi">Aswathi/Ashwini</option>
                        <option value="bharani">Bharani</option>
                        <option value="karthika">Karthika/Krittika</option>
                        <option value="rohini">Rohini</option>
                        <option value="makeeram">Makeeram/Mrigashirsa</option>
                        <option value="thiruvathira">Thiruvathira/Ardra</option>
                        <option value="punartham">
                          Punartham/Punarvasu/Punarpoosam
                        </option>
                        <option value="pooyam">Pooyam/Pushya</option>
                        <option value="aayilyam">Aayilyam/Ashlesha</option>
                        <option value="makam">Makam/Magha</option>
                        <option value="pooram">Pooram/Purva Phalguni</option>
                        <option value="uthram">Uthram/Uttara Phalguni</option>
                        <option value="atham">Atham/Hasta</option>
                        <option value="chithira">Chithira/Chitra</option>
                        <option value="chothi">Chothi/Swati</option>
                        <option value="vishaakam">Vishaakham/Vishakha</option>
                        <option value="anizham">
                          Anizham/Anuradha/Anusham
                        </option>
                        <option value="thriketta">
                          Thriketta/Jyeshtha/Kettai
                        </option>
                        <option value="moolam">Moolam/Mula</option>
                        <option value="pooraadam">Pooraadam/Purvashada</option>
                        <option value="uthraadam">Uthraadam/Uthrashada</option>
                        <option value="thiruvonam">Thiruvonam/Shravana</option>
                        <option value="avittam">Avittam/Dhanishtha</option>
                        <option value="chathayam">Chathayam/Satabisha</option>
                        <option value="pooruruttaathi">
                          Pooruruttaathi/Poorva bhadrapada
                        </option>
                        <option value="uthrattathi">
                          Uthrattathi/Uttara bhadrapada
                        </option>
                        <option value="revathi">Revathi</option>
                      </Field>
                      <ErrorMessage
                        name="personStar"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="message" className="form__label">
                        Message
                      </label>
                      <Field
                        as="textarea"
                        rows="4"
                        name="message"
                        id="message"
                        className="form__input"
                      />
                      <ErrorMessage
                        name="message"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="hidden">
                      <label>Do you align with our policy</label>
                      <Field name="policy" type="checkbox"></Field>
                    </div>
                    <div className="button-wrap">
                      <button
                        className="button"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Apply
                      </button>
                      <button className="link_text" type="reset">
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  )
}

export default ElectionalAstrology
